@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@media print {

    /* Specifically target Tailwind dark mode text colors */
    .dark\:text-white,
    .dark\:text-gray-400,
    .dark\:text-gray-300,
    .dark\:text-gray-200,
    .text-white,
    .text-gray-400,
    .text-gray-300,
    .text-gray-200 {
        color: black !important;
    }

    /* Specifically target Tailwind dark mode background colors */
    .dark\:bg-gray-800,
    .dark\:bg-gray-700,
    .bg-gray-800,
    .bg-gray-700 {
        background-color: white !important;
    }

    /* Handle links to ensure they are readable */
    a {
        color: black !important;
    }

    /* Ensure buttons and inputs are styled correctly */
    button, input, select, textarea {
        color: black !important;
        background-color: white !important;
        border-color: black !important;
    }

    /* Optional: Hide non-essential elements */
    .no-print {
        display: none !important;
    }
}
